@import reset
@import variables
@import aboutModal
@import accordion
@import header
@import instructions
@import footer

body
	font:
		family: 'San Francisco', -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', 'Helvetica Neue', Helvetica, sans-serif
		size: $font-size
		weight: 400
	background: $white
	line-height: $line-height
	overflow: hidden

main svg
	height: 100vh
	width: 100vw
	cursor: grab
	&:active
		cursor: grabbing
	g > path
		cursor: crosshair

		&:hover
			opacity: 1
		&:active
			cursor: grabbing

p
	margin-bottom: $font-size * $line-height
	&:last-of-type
		margin: 0

h2, h3
	font:
		size: 13px
		weight: 700
	line-height: inherit

.icon
	width: 24px
	height: 24px
	display: block

.blue
	color: $blue
.green
	color: $green
.magenta
	color: $magenta

.stroke
	border: 1px solid $light-gray

.faded
	color: $gray

code
	padding: 0 .25rem
	margin: 0 .0625rem
	border-radius: .125rem
	background-color: $light-gray
	font-family: monospace