.about
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	border: 0
	&.hidden
		display: none
	a
		color: inherit
		text-decoration: none
		&:hover
			color: $gray

.about--tint
	background: transparentize($light-gray, 0.1)
	width: 100%
	height: 100%
	cursor: pointer


.about--modal
	position: fixed
	left: 50%
	top: 50%
	transform: translate(-50%, -50%)
	background: $white
	padding: $spacer * 2
	width: 100%
	max-width: 550px
	height: 550px

	li
		border-top: 1px solid $light-gray
		&:last-of-type
			border-bottom: 1px solid $light-gray

.about--header
	margin-bottom: $spacer * 2
	width: 100%
	background: $white
	&::after
		content: ""
		display: table
		clear: both
	h2
		float: left
		line-height: 24px
	.about--close
		float: right
		cursor: pointer
		transition: $transition
		opacity: 1
		&:hover
			transition: $transition
			opacity: 0.5
.accordion
	list-style: none
	overflow-y: auto
	height: calc(100% - 60px)


.people
	margin-bottom: $font-size * $line-height
	.person,
	.person:last-of-type
		border: 0

.accordion--content
	width: calc(100% - 39px)
	max-width: 360px


@media (max-height: 600px)
	.about--modal
		height: 450px