header
  position: fixed
  top: 0
  left: 0
  right: 0
  height: $bar-height
  border-bottom: 1px solid $light-gray
  background: $background
  line-height: $bar-height
  &::after
    content: ""
    display: table
    clear: both

  h1
    font:
      size: 14px
      weight: 700
    line-height: inherit
    float: left
    padding-left: $spacer

  nav
    float: right
  ul
    list-style: none
  li
    display: inline-block
    padding: 0 $spacer

  a
    text-decoration: none
    color: $black
    transition: $transition
    cursor: pointer
    &:visited
      text-decoration: none
      color: $black
    &:hover
      transition: $transition
      color: $gray
    &.noLink
      cursor: default
      &:hover
        color: $black

.navigation
  li
    position: relative
    &:hover
      > .navigation--dropdown
        display: block
.navigation--dropdown
  background: $background
  position: absolute
  display: none
  margin:
    top: -1px
    left: -$spacer
  border-right: 1px solid $light-gray
  border-left: 1px solid $light-gray
  border-bottom: 1px solid $light-gray
  border-top: 1px solid $white
  padding: 0 $spacer
  line-height: 20px
  li
    color: $black
    margin: 0
    padding: 0
    width: 100%
    line-height: $bar-height
    border-top: 1px solid $light-gray



.navigation--toggle
  display: none
  float: right
  padding: 8px
  box-sizing: content-box
  margin-right: 7px
  cursor: pointer

header
  .navigation--open
    display: block
  .navigation--close
    display: none
  &.open
    .navigation--open
      display: none
    .navigation--close
      display: block

    nav
      display: block
      float: unset
      position: fixed
      top: 40px
      left: 0
      width: 100%
      background: $background
      border-bottom: 1px solid $light-gray
      padding: 0 $spacer

      li
        padding: 0
        display: block
        border-bottom: 1px solid $light-gray
        &:last-of-type
          border-bottom: 0
        &:first-of-type
          &::after
            content: ''
            position: absolute
            top: 40px
            left: 0
            width: 100%
            border-bottom: 1px solid $light-gray

    .navigation--dropdown
      display: block
      position: relative
      padding: 0 0 0 $spacer
      margin: 0
      border: 0
      li
        border-top: 0

@media (max-width: 400px)
  .navigation--toggle
    display: block
  nav
    display: none
