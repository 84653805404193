.accordion
	.accordion--content
		display: none
		padding: 11px 0 22px 0
		img
			display: block
			width: 100%
			margin: $line-height-px 0 ($line-height-px * 2)

	.accordion--header
		line-height: $bar-height
		&::after
			content: ""
			display: table
			clear: both

	.accordion--togggle
		position: relative
		float: right
		user-select: none
		cursor: pointer
		opacity: 1
		transition: $transition
		margin: 8px 0
		&:hover
			transition: $transition
			opacity: 0.5

	.accordion--open,
	.accordion--close
		position: absolute
		top: 0
		right: 0

	.accordion--open
		display: block
	.accordion--close
		display: none

.open
	.accordion--content
		display: block
	.accordion--header
		border-bottom: 1px solid $light-gray
	.accordion--open
		display: none
	.accordion--close
		display: block