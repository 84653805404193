
// Typography
$font-size: 13px
$line-height: 1.4
$line-height-px: $font-size * $line-height

// Composition
$bar-height: 40px
$spacer: 15px

// Life
$transition: all 0.2s ease-in-out

// Color Definitions
$blue: #0081c3
$green: #00a94f
$magenta: #e24486
$black: #000
$white: #fff
$light-gray: #dadde1
$gray: #999
$background: transparentize($white, .08)